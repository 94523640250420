.dot-status {
    display: table;
    border-radius: 100%;
    border: none;
    float: left;
    margin-right: 10px;

    &.approved {
        background-color: #2FAA59;
    }

    &.pending {
        background-color: #4BF93C;
    }

    &.wating {
        background-color: #3A5273;
    }
}