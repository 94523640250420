$buttons-size: 130px;

#product-edit {
    .subproduct-images {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        button {
            width: calc(33.33333% - 10px);
            height: 170px;
            border: none;
            background-color: transparent;
            padding: $half-elements-margin;
            border: solid 1px $box-border-color;
            margin-bottom: 15px;

            &.active {
                border-color: $primary-color;
                box-shadow: 9px 8px 13px rgb(0 0 0 / 15%);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .product-images {
        display: flex;
        flex-wrap: wrap;

        > div {
            position: relative;
            border: solid $input-border-width $input-label-color;
            width: $buttons-size;
            height: $buttons-size;
            border-radius: $border-radius-default;
            margin-right: $elements-margin;
            display: flex;
            align-items: center;
            justify-content: center;

            > button {
                position: absolute;
                top: 5px;
                right: 0;
                padding-right: 12px;
            }

            img {
                width: calc(100% - 10px);
                height: calc(100% - 10px);
                object-fit: contain;
            }
        }

        > button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: $buttons-size;
            height: $buttons-size;
            border: solid $input-border-width $input-label-color;
            border-radius: $border-radius-default;
            margin-right: $elements-margin;

            span {
                text-align: center;
                padding-top: 10px;
                color: $primary-color;
                font-weight: 500;

                small {
                    color: rgb(131, 131, 131)
                }
            }
        }
    }

    .btn-change-image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 29px;
        padding: 0;
        border: none;
        width: 100%;
        height: 50px;
        background-color: transparent;
        
        span {
            font-size: 12px;
        }

        img {
            margin-bottom: 5px;
            margin-top: 5px;
            width: 100%;
            height: 30px;
            object-fit: contain;
        }
    }
}