.modal-close {
    .section {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 100px;
            margin-bottom: 30px;
        }
    }
}