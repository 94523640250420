.filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: $elements-margin;

    > span {
        font-size: 14px;
        color: $text-secondary-color;
        margin-right: 15px;
    }
}