.chart {
    width: 40%;
    margin: 10px auto;
}

.chart-table {
    width: 100%;
    td {
        font-size: 14px;
        padding: 5px 3px;
    }
}