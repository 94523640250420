.box-money {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .description {
        color: $text-secondary-color;
        font-size: 14px;
        text-align: center;
        width: 100%;
        display: table;
    }
    .value {
        text-align: center;

        .currency {
            font-size: 18px;
            margin-right: 5px;
            font-weight: 500;
        }
    
        .cash {
            font-size: 28px;
            font-weight: 700;
        }
    
        .cents {
            font-size: 18px;
            margin-left: 5px;
            font-weight: 500;
        }
    }
}
