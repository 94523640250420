.page-header {
    padding-bottom: $elements-margin;
    display: flex;
    justify-content: space-between;

    .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        > * {
            margin-right: $elements-margin;
        }
    }

    .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
        > * {
            margin-left: $elements-margin;
        }
    }
}