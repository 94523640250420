.page-footer {
    display: flex;

    > *:not(:last-child) {
        margin-right: $elements-margin;
    }

    > div {
        display: flex;

        > *:not(:last-child) {
            margin-right: $elements-margin;
        }
    }

    &.space-between {
        justify-content: space-between;
    }
}