.box-count {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .description {
        color: $text-secondary-color;
        font-size: 14px;
        text-align: center;
        width: 100%;
        display: table;
    }
    .value {
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        width: 100%;
        display: table;
    }
}
