.check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: solid $input-border-width $input-border-color;
    border-radius: $border-radius-default;

    &.active::after {
        content: "";
        display: table;
        width: 10px;
        height: 10px;
        background-color: $input-label-color;
    }
}