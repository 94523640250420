.input-checkboxes-group {
    position: relative;
    width: 100%;
    margin-bottom: $input-margin-bottom;
    height: $input-boxes-height;
    border: solid $input-border-width $input-label-color;
    border-radius: $border-radius-default;
    overflow-y: auto;

    .options {
        border-radius: 3px;
        display: flex;
        flex-direction: column;

        .option {
            width: 100%;
            border: none;
            padding: 0;
            background-color: transparent;
            margin-top: 18px;
            display: flex;
            align-items: center;
            padding-left: 15px;
        }
    }
}