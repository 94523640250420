.input-radio-group {
    position: relative;
    width: 100%;
    margin-bottom: $input-margin-bottom;
    margin-top: 5px;

    label {
        font-size: $input-label-font-size;
        font-weight: $input-label-weight;
        color: $input-label-color;
        position: absolute;
        left: 0;
        top: -8px;
    }

    .options {
        display: flex;
        flex-wrap: wrap;

        .option {
            border: none;
            padding: 0;
            background-color: transparent;
            margin-top: 22px;
            display: flex;
            align-items: center;
            margin-right: 14px;

            &:last-child {
                margin-right: 0;
            }

            > div {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 18px;
                height: 18px;
                margin-right: 5px;
                border-radius: 100%;
                border: solid $input-border-width $input-label-color;
            }

            &.active {
                > div::after {
                    content: "";
                    display: table;
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    background-color: $input-label-color;
                }
            }
        }
    }

    &.no-margin {
        margin-bottom: 0;
        
        .option {
            margin-top: 0;
        }
    }
}