.filter-simple {
    display: flex;
    height: 35px;

    input {
        font-size: 14px;
        padding: 10px;
        border: solid 1px $box-border-color;
        border-right: none;
        height: 100%;
        border-radius: 3px 0 0 3px;
    }
}